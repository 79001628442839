import styled from "styled-components";

//#region [Props]
type SMaxWidthContainerProps = {
	$forContent?: boolean;
}
//#endregion

//#region [Styles]
const SMaxWidthContainer = styled.div<SMaxWidthContainerProps>`
	margin: 0 auto;
	max-width: 1440px;
	width: 100%;

	${props => props.$forContent && `max-width: 800px;`}
`;

export default SMaxWidthContainer;
//#endregion