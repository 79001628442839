import FacebookSvg from "@/public/FacebookMark1.svg";
import InstagramSvg from "@/public/InstagramMark1.svg";
import TwitterSvg from "@/public/TwitterMark1.svg";
import { PortableTextBlock } from "@sanity/types";
import SMaxWidthContainer from "components/framework/SMaxWidthContainer";
import RichText from "components/richtext/RichText";
import { toRem } from "lib/client/fonts";
import PublicRuntimeConfig from "lib/types/PublicRuntimeConfig";
import { ICommonAppData } from "lib/types/sanity";
import { ISoMeLink } from "lib/types/Settings";
import getConfig from "next/config";
import Link from "next/link";
import styled from "styled-components";

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: PublicRuntimeConfig };

//#region [Styles]
const SFooter = styled.footer`
	background-color: var(--color_footer_bg);
	padding: 25px 10px;
	@media screen and (min-width: 641px) {
		padding: 50px 10px;
	}
`;


const SFooterContent = styled(SMaxWidthContainer)`
	word-break: normal;
	overflow-wrap: anywhere;

	@media screen and (min-width: 950px) {
		display: grid;
		gap: 30px;
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}


	color: var(--color_footer_text);

	a {
		color: var(--color_footer_text);
		text-decoration: underline;
	}

`;

const SCenteredContent = styled.div`

	grid-column: 1 / span 3;
	text-align: center;
`;

const SSoMeLinks = styled.div`
	margin-bottom: 25px;

	grid-column: 1 / span 3;
	text-align: center;
	display: flex;
	gap: 3rem;
	justify-content: center;

`;
//#endregion

//#region [Props]
type FooterProps = {
	appData: ICommonAppData;
}
//#endregion

//#region [Component]
export default function Footer({ appData }: FooterProps) {
	if (!appData?.footer) {
		return null;
	}
	return <SFooter>
		<SFooterContent>

			<FooterColumn col={appData.footer.col1} />

			<FooterColumn col={appData.footer.col2} />

			<FooterColumn col={appData.footer.col3} />

			{(appData.settings?.footer_some_links?.length ?? 0) > 0 && <SSoMeLinks>
				{appData.settings.footer_some_links!.map(someLink => <SoMeLink key={someLink._key} link={someLink} />)}
			</SSoMeLinks>}
			<SCenteredContent>Nettsiden er utviklet av Filmweb. Vi følger retningslinjer for <Link href={publicRuntimeConfig.NEXT_PUBLIC_FILMWEB_PRIVACYPOLICY_URL} legacyBehavior><a target="_blank">personvern og bruker cookies.</a></Link></SCenteredContent>

		</SFooterContent>
	</SFooter>
}
//#endregion

//#region [Styles]
const SFooterColumn = styled.section`
	margin-bottom: 25px;

	h1 {
		font-size: ${toRem(18)};
		@media screen and (min-width: 641px) {
			font-size: 2.5rem;
		}

		margin: 0 0 .5rem 0;
	}

	p {
		line-height: 1.6;
	}

	img {
		max-width: 50%;
		position: static;
		&+img {
			margin-top: 1em;
		}
	}
`;
//#endregion

//#region [Props]
type FooterColumnProps = {
	col: PortableTextBlock[]
}
//#endregion


//#region [Component]
function FooterColumn({ col }: FooterColumnProps) {
	if (!col) {
		return null;
	}
	return <SFooterColumn>
		<RichText value={col} />
	</SFooterColumn>
}
//#endregion


//#region [Styles]
const SSoMeLink = styled.a`
	width: 70px;
	height: 70px;
	display: block;

	svg {
		display: block;
		width: 100%;
	}
`;

//#endregion

//#region [Props]
type SoMeLinkProps = {
	link: ISoMeLink;
}
//#endregion

const iconsMap: Record<string, JSX.Element> = {
	"facebook": <FacebookSvg />,
	"twitter": <TwitterSvg />,
	"instagram": <InstagramSvg />
};

//#region [Component]
function SoMeLink({ link }: SoMeLinkProps) {
	return <Link href={link.url} passHref legacyBehavior><SSoMeLink target="_blank"  aria-label={link._type}>{iconsMap[link._type]}</SSoMeLink></Link>
}
//#endregion