import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
import { format, parse } from "date-fns";
import locale from "date-fns/locale/nb";
import IProgram, { ICurrentMoviesProgram, ITheatreProgram, ITrailerEmbedCode } from "lib/types/Program";

/**
 * Query client for use in client side queries
 */
export const queryClient = new QueryClient();

/**
 * Gets a video embed code
 * @param clipId The TwentyThree video id
 * @returns the embed code for the video
 */
export async function getTrailerEmbedCode(clipId: string): Promise<string | undefined | null> {
	const result = await axios.get<ITrailerEmbedCode>("/api/trailerembed", { params: { clipid: clipId } });
	return result?.data?.embedCode;
}

/**
 * Gets the movie/event program
 * @param movieId if a single movie, set the movie ID here (EDI)
 * @param date if a single date, set the date here (format: yyyy-MM-dd if string)
 * @param includeDocuments wether or not to also include sanity documents for the movie
 * @param first only get the first X number of shows
 * @param groupMovies should movies be grouped,
 * @param showType restrict the program to the given show type
 * @returns The program
 */
export async function getProgram(movieId?: string, date?: Date | string, includeDocuments?: boolean, first?: number, groupMovies?: boolean, showType?: string): Promise<IProgram | ICurrentMoviesProgram> {
	let dateStr: string | undefined;
	if (date instanceof Date) {
		dateStr = format(date, 'yyyy-MM-dd', { locale });
	} else {
		dateStr = date;
	}

	const result = await axios.get<IProgram | ICurrentMoviesProgram>("/api/program", { params: { movieId, date: dateStr, includeDocuments, first, groupMovies, showType } });
	const programData = result?.data;
	if (groupMovies) {
		(programData as ICurrentMoviesProgram)?.movies?.forEach(movie => {
			movie.shows?.forEach(show => show.showStartDate = parse(show.showStart, "yyyy-MM-dd'T'HH:mm:ss", new Date(), { locale }));
		});
	} else {
		(programData as IProgram)?.shows?.forEach(show => show.showStartDate = parse(show.showStart, "yyyy-MM-dd'T'HH:mm:ss", new Date(), { locale })); // convert the date strings into real dates
	}



	return programData;

}

/**
 * Gets the event program
 * @param kulNr if a single event, set the KUL nr here
 * @param date if a single date, set the date here (format: yyyy-MM-dd if string)
 * @param includeDocuments wether or not to also include sanity documents for the movie
 * @param first only get the first X number of shows
 * @returns The program
 */
export async function getCultureProgram(kulNr?: string, date?: Date | string, includeDocuments?: boolean, first?: number): Promise<ITheatreProgram> {
	let dateStr: string | undefined;
	if (date instanceof Date) {
		dateStr = format(date, 'yyyy-MM-dd', { locale });
	} else {
		dateStr = date;
	}

	const result = await axios.get<ITheatreProgram>("/api/culture", { params: { eventId: kulNr, date: dateStr, includeDocuments, first } });
	const programData = result?.data;

	programData?.shows?.forEach(show => {
		show.showStartDate = parse(show.showStart, "yyyy-MM-dd'T'HH:mm:ss", new Date(), { locale });
	});

	return programData;

}

/**
 * Gets all dates with shows
 * @param eventsOnly wwether or not only events should be included
 */
export async function getDaysWithShows(eventsOnly: boolean = false): Promise<Date[] | undefined> {

	const result = await axios.get<string[]>("/api/daysWithShows", { params: { eventsOnly } });
	const programData = result?.data;
	return programData?.map(date => parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date(), { locale })); // convert the date strings into real dates

}

export function sortCurrentMoviesProgramByFirstShow(program: ICurrentMoviesProgram):ICurrentMoviesProgram {
	if ((program?.movies?.length ?? 0) > 0) {
		program.movies.sort((m1, m2) => {
			const s1 = m1.shows?.[0];
			const s2 = m2.shows?.[0];
			return (s1?.showStartDate?.getTime() ?? 0) - (s2?.showStartDate?.getTime() ?? 0);
		});
	}
	return program;
}