import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

//#region [Styles]
const SSignupForm = styled.div`
	input {
		line-height: 1.5;
		font-family: "Open Sans",Helvetica,Arial,sans-serif;
		font-size: 100%;
	}

	input[type='email'] {
		display: block;
		min-width: 80%;
		padding: 0.25rem 0.5rem;
		color: #000000;

	}

	input[type='submit'] {
		color: inherit;
		background: none;
    	border: 2px solid currentColor;
    	text-transform: uppercase;
    	padding: 0.25rem 0.5rem;
    	margin-top: 0.5rem;
	}
`;

const SLabel = styled.label`
	font-size: 0.9rem;
`;

const STermsError = styled.div`
	color: red;
`;
//#endregion

//#region [Props]
type MailChimpSignupFormWithUrlProps = {
	value: {
		signupUrl: string;
		gdprFormId: number;
	};
};
//#endregion


//#region [Component]
export default function MailChimpSignupFormWithUrl({ value }: MailChimpSignupFormWithUrlProps) {
	const [hasAcceptedTc, setHasAcceptedTc] = useState(false);
	const [showTcError, setShowTcError] = useState(false);
	const formRef = useRef<HTMLFormElement>(null);
	const formData = useMemo(() => {
		if( !value.signupUrl) {
			return null;
		}
		const regexExtractor = /^(?:http:)?\/\/.*?\/subscribe\/post\?u=(.*?)&(?:amp;)?id=(.*)$/gi;

		const extracted = regexExtractor.exec(value.signupUrl.trim());
		return {
			u: extracted?.[1],
			id: extracted?.[2]
		};

	}, [value.signupUrl]);

	const onSubmit = useCallback((event: SubmitEvent) => {
		event.preventDefault();
		if (!hasAcceptedTc) {
			setShowTcError(true);
		} else {
			formRef.current?.submit();
			setShowTcError(false);
			console.log("Form submitted");
		}

	}, [hasAcceptedTc]);

	useEffect(() => {
		//const button = document.querySelector("#mc-newsletter-signup input[type='button']") as HTMLInputElement;
		if (formRef.current) {
			const form = formRef.current;
			form.addEventListener("submit", onSubmit);
			return () => { form.removeEventListener("submit", onSubmit) };
		}
	}, [onSubmit]);

	if (!formData) {
		return null;
	}

	return (<div>
		<SSignupForm data-newsletter-count="1">
			<div>
				<form action={value.signupUrl} method="post" name="mc-embedded-subscribe-form" target="_blank" noValidate ref={formRef}>
					<div>
						<div>
							<input type="email" name="EMAIL" id="mce-EMAIL0" placeholder="E-post" />
							<input type="submit" value="Meld deg på" name="subscribe"/>
						</div>
						<div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
							<input type="text" name={`b_${formData.u}_${formData.id}`} tabIndex={-1} value="" />
						</div>
					</div>
				</form>
			</div>
		</SSignupForm>
		<div>
			<SLabel htmlFor="personvernCheckbox0">
				<input id="personvernCheckbox0" name="personvernCheckbox0" type="checkbox" checked={hasAcceptedTc} onChange={() => setHasAcceptedTc(!hasAcceptedTc)} /> Godta <a href={`https://skynet.filmweb.no/filmwebforms/form/displayform?id=${value.gdprFormId}`} target="_blank" rel="noreferrer">personvernerklæringen</a>
			</SLabel>
			{showTcError && <STermsError>Du må godta personvernerklæringen</STermsError>}
		</div>
	</div>);
}
//#endregion