//import BackArrowSvg from "@/public/ArrowBack.svg";
import ForsidenIcon from "@/public/ForsidenIcon.svg";
import DropDownMenu from 'components/framework/DropDownMenu';
import Hotlink from 'components/framework/Hotlink';
import CMSImage from 'components/images/Image';
import { ICommonAppData } from 'lib/types/sanity';
import { Hotlink as NavHotLink } from 'lib/types/Settings';
import Link from "next/link";
import { useRouter } from 'next/router';
import { useEffect, useState } from "react";
import styled from 'styled-components';
import SCloseButton from "components/framework/SCloseButton";

//#region [Styles]


const SHeader = styled.header`
	background-color: var(--color_menu_bg, #fff);
	height: var(--top-menu-height, 80px);
	max-width: 100vw;
	position: relative;
	z-index: 1000;
	padding: 0 10px 0 0;

	@media screen and (min-width: 641px) {
		height: 112px;
	}
`;

const SMenuContainer = styled.div`
	margin: 0 auto;
	height: 100%;
	max-width: 1440px;
	display: flex;
	align-items: center;
	gap: 1em;

`;

type CenteredLogoProps = {
	$centeredLogo: boolean;
};

const SLogoLink = styled.a<CenteredLogoProps>`
	flex: 0 1 85%;
	margin-left: 20px;
	max-height: 100%;
	height: 100%;
	max-height: 65px;
	display: flex;
	align-items: center;

	@media screen and (min-width: 641px) {
		max-width: 60%;
		flex: 1 1 60%;
		max-height: 90px;
		${props => props.$centeredLogo && `
			max-width: calc(100% - 58px);
			flex: 1 1 calc(100% - 58px);
			text-align: center;

			img {
				display: inline-block;
				transform: translateX(58px);
			}
		`}
	}
`;

const SLogoImage = styled(CMSImage)`
	display: block;
	width: auto;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;

	@media screen and (min-width: 641px) {
		max-height: 90px;
	}
`;

const SMenuLinks = styled.div<CenteredLogoProps>`
	flex: 0 1 calc(15% - 2em);
	@media screen and (min-width: 641px) {
		max-width: 40%;
		flex: 1 1 40%;
		display: flex;
		justify-content: flex-end;
		gap: 5%;

		${props => props.$centeredLogo && `
			max-width: 58px;
			flex: 1 1 58px;
		`}
	}

`;

/*
type SBackButtonProps = {
	isRoot: boolean;
}

const SBackButton = styled.button<SBackButtonProps>`
	flex: 1 1 calc(15% - 2em);
	border: none;
	background: none;
	padding: 0;
	margin: 0;
	${props => props.isRoot === true && `
		pointer-events: none;
		svg {
			display: none;
		}
	`}

	@media screen and (min-width: 641px) {
		pointer-events: none;
		display: none;
	}
	svg {
		width: 50%;
		@media screen and (min-width: 641px) {
			display: none;
		}
	}

`;
*/


//#endregion

//#region [Props]
type MenuProps = {
	appData: ICommonAppData;
	className?: string;
};
//#endregion

//#region [Component]  Menu
const Menu = ({ appData, className }: MenuProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const router = useRouter();

	useEffect(() => {
		setIsOpen(false);
	}, [router.pathname, router.query]);

	const settings = appData?.settings;
	if (!settings) {
		return null;
	}

	const isLogoCentered = settings.centerLogo && (settings.hotlinks?.length ?? 0) === 0;

	return (<>
		<SHeader className={className}>
			<SMenuContainer>
				{settings.logo &&

					<Link href="/" passHref legacyBehavior>
						<SLogoLink $centeredLogo={isLogoCentered} aria-label="Gå til forsiden">
							<SLogoImage image={settings.logo} altText={settings.cinemaName} priority unoptimized width={1000}/>
						</SLogoLink>
					</Link>
				}
				<SMenuLinks $centeredLogo={isLogoCentered}>
					{settings.hotlinks?.map(hotlink => <Hotlink key={hotlink._key} hotlink={hotlink} $hideOnSmallScreens useAnchors={settings.program_view === "none"}/>)}
					<SCloseButton $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>Meny</SCloseButton>
				</SMenuLinks>
			</SMenuContainer>
			{appData.menu?.dropDownMenu && <DropDownMenu isOpen={isOpen} setIsOpen={setIsOpen} menuItems={appData.menu.dropDownMenu} hotlinks={settings.hotlinks!} useAnchors={settings.program_view === "none"}/>}

		</SHeader>

		{settings.hotlinks && <NavHotBar hotlinks={settings.hotlinks!} useAnchors={settings.program_view === "none"}/>}
	</>);

};

export default Menu;
//#endregion

//#region [Styles]
const SMobileMenu = styled.nav<MobileMenuProps>`
	height: var(--mobile-menu-height);
	display: flex;
	position: fixed;
	bottom: 0;
	width: 100vw;
	z-index: 9000;
	font-size: 0.7rem;
	background-color: var(--color_menu_bg, #fff);

	justify-content: space-between;
	@media screen and (min-width: 641px) {
		display: none;
	}


	a {
		padding: 8px;
		color: var(--color_menu_text, #000);
		flex: 1 1 ${props => 100 / props.$numItems}%;
		text-align: center;

		svg {
			height: 24px;
			width: auto;
			display: block;
			margin: 0 auto;
		}
	}

`;
//#endregion

//#region [Props]
type NavHotBarProps = {
	hotlinks: NavHotLink[];
	useAnchors?: boolean;
};

type MobileMenuProps = {
	$numItems: number;
}
//#endregion

//#region [Component]
function NavHotBar({ hotlinks, useAnchors = false }: NavHotBarProps) {
	return <SMobileMenu $numItems={hotlinks.length + 1}>
		<Link href="/">
			<ForsidenIcon />
			Forsiden
		</Link>
		{hotlinks.map(hotlink => <Hotlink key={hotlink._key} hotlink={hotlink} useAnchors={useAnchors} />)}
	</SMobileMenu>
}
//#endregion