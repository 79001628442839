import React, { ReactNode, ErrorInfo } from "react"
interface Props {
	children?: ReactNode;
	boundaryName: string;
	values?: object;
}

interface State {
	hasError: boolean;
	error?: Error;
}

class ErrorBoundary extends React.Component<Props, State> {
	public state: State = {
		hasError: false
	};

	public static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error: _ };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error("Uncaught error:", error, errorInfo);
	}


	public render() {
		// Check if the error is thrown
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div>
					<h2>Oops, there is an error: {this.props.boundaryName}</h2>
					<pre>
						{this.state.error?.message}

					</pre>
					<pre>
						{this.state.error?.stack}
					</pre>
					{this.props.values && <pre>
						{JSON.stringify(this.props.values)}
					</pre>}
				</div>
			)
		}

		// Return children components in case of no error

		return this.props.children
	}
}

export default ErrorBoundary