import { Filmomtale } from "@filmweb/sanity-types";
import { DocumentType_CULTURE, ICultureArticle, IMovieArticle, INewsArticle, ISpecialShowArticle, Settings } from "lib/types/sanity";

export function useBaseTitle(settings: Settings, pageTitle?: string): string {
	const title = `${pageTitle ? `${pageTitle} - ` : ""}${settings?.cinemaName ?? ""}${process.env.NODE_ENV === "development" ? " - DEV" : ""}`;
	return title;
}

type Doc = ICultureArticle | INewsArticle | IMovieArticle | ISpecialShowArticle | Filmomtale;

export function useDocumentTitle(settings: Settings, document: Doc): string {
	const baseTitle = useBaseTitle(settings);
	let docTitle = `${document.titleOverride ?? document.title} - ${baseTitle}`;
	if( (settings.canOverrideMovieTitles || document._type === DocumentType_CULTURE) && document.titleOverride) {
		docTitle = `${document.titleOverride as string} - ${baseTitle}`;
	}
	return docTitle;
}