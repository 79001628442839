import { ICommonAppData } from 'lib/types/sanity';
import { createContext, useEffect, useReducer } from 'react';

type HasAdContextType = {
	[key: number]: boolean;
}

const HasAdContext = createContext<HasAdContextType>({});

export { HasAdContext };

//#region [Props]
type AdContextWrapperProps = {
	children: React.ReactNode;
	appData: ICommonAppData,
	isSkjermProgram: boolean;
};
//#endregion

type NoMatchedAdsArgs = {
	auId: string;
	targetId: string;
}

type AdReducerAction = {
	index: number;
	isAdLoaded: boolean;
};

function adReducer(state: HasAdContextType, action: AdReducerAction) {
	const newState = { ...state, [action.index]: action.isAdLoaded };
	return newState;
}

//#region [Component]
export default function AdContextWrapper({ children, appData, isSkjermProgram }: AdContextWrapperProps) {
	const [adState, adDispatch] = useReducer(adReducer, {}, (initialArg) => {
		return appData?.settings?.adIds?.reduce<HasAdContextType>((accumulator, currAdId, currIndex) => {
			accumulator[currIndex] = true;
			return accumulator;
		}, initialArg) ?? initialArg;
	});

	useEffect(() => {
		if (isSkjermProgram) return;
		if (window && (appData?.settings?.adIds?.length ?? 0) > 0) {
			const w = (window as any);
			w.adn = w.adn || {};
			w.adn.calls = w.adn.calls || [];
			w.adn.calls.push(function () {
				//console.debug("Request called");
				//w.adn.setFeedbackOptions({ console: 'error', inScreen: 'silent' })
				w.adn.request({
					requestParams: { load: 'lazyRequest', proximity: -100 }, // 09.05.2023 proximity adjusted to 0 from 50
					clearTarget: true,
					//requestMode: "hasTarget",
					//gdpr: 1,
					adUnits: appData.settings!.adIds!.map(adUnit => ({
						//protocol: "https",
						auId: adUnit.adId,
						targetId: adUnit.adPlacementName,
						//auW: "100%"
						auW: '2000',
						auH: '300'
					})),


					onNoMatchedAds: (ad: NoMatchedAdsArgs) => {
						console.debug("No matched ads", ad.auId, ad.targetId);
						const index = appData.settings.adIds!.findIndex(configuredAd => configuredAd.adId === ad.auId);
						if (index !== -1) {
							adDispatch({ index, isAdLoaded: false });
						}
					},
					/*
					onViewable: (ad: any) => {
						console.debug("onViewable", ad);
					},
					onVisible: (ad: any) => {
						console.debug("onVisible", ad);
					}*/
				});
			});
		}
	}, [appData?.settings, isSkjermProgram]);


	return <HasAdContext.Provider value={adState}>{children}</HasAdContext.Provider>;
}
//#endregion
