import { type Filmomtale, type ICustomImage } from "@filmweb/sanity-types";
import { Image, Reference } from "@sanity/types";
import { urlForAsset } from "lib/client/sanity";
import { FwPakkeImageDocument, ICultureArticle, IMovieArticle, INewsArticle } from "lib/types/sanity";
import { toNumber } from "lodash";
import NextImage, { ImageLoader, StaticImageData } from "next/image";
import React, { ReactNode, useCallback, useMemo } from "react";
import styled from 'styled-components';
import Head from "next/head";
import { ImageUrlBuilder } from "@sanity/image-url/lib/types/builder";
import { GLOBAL_POSTER_ASPECT } from "lib/server/constants";
import { getOrgSize } from "lib/utils/getOrgSize";

//#region [Props]
type ImageProps = {
    priority?: boolean;
    altText?: string;
    className?: string;
    sizes?: string;
    width?: number | string;
    height?: number | string;
    crop?: boolean; // THIS IS CURRENTLY INTENDED FOR POSTERS
    isPosterAspect?: boolean;
};

type CMSImageProps = ImageProps & {
    image: FwPakkeImageDocument | ICustomImage;
    isFilmweb?: boolean;
    unoptimized?: boolean;
};
//#endregion

//#region [Other]
const cmsImageLoader: ImageLoader = (p) => {
    return urlForAsset(p.src).width(p.width).auto("format").url();
}

const filmwebCmsImageLoader: ImageLoader = (p) => {
    return urlForAsset(p.src, true).width(p.width).auto("format").url();
}

function getImageLoader(isFilmweb: boolean, crop: boolean, cropAspect: number): ImageLoader {
    let imgLoader: ImageLoader;
    if (isFilmweb) {
        imgLoader = (p) => {
            let builder = urlForAsset(p.src, true);
            if (crop) {
                builder = doCrop(builder).width(p.width).height(Math.floor(p.width / cropAspect));

            }
            return builder.width(p.width).auto("format").url();
        };

    } else {
        imgLoader = (p) => {
            let builder = urlForAsset(p.src);
            if (crop) {
                builder = doCrop(builder).width(p.width).height(Math.floor(p.width / cropAspect));
            }
            return builder.width(p.width).auto("format").url();
        };
    }

    return imgLoader;
}

//#endregion



//#region [Component]
export default function CMSImage({ image, priority = false, altText, className, sizes, width, height, isFilmweb = false, unoptimized = false, crop = false, isPosterAspect = false }: CMSImageProps) {
    if (!image) {
        return null;
    }

    let assetRef: string | undefined;
    try {
        assetRef = isFilmweb ? (image as ICustomImage).asset._id : (image?.image as Image)?.asset?._ref;
    } catch (ex) {
        console.error(`image ${image}, ${altText}`);
    }

    if (!assetRef) {
        return null;
    }
    const size = getOrgSize(assetRef);
    const w = toNumber(width ?? size.width);
    const h = Math.round(toNumber(height ?? w / size.aspect));
    let imgSrc = assetRef;
    if (unoptimized) {
        let imgBuilder = urlForAsset(assetRef, isFilmweb).auto("format");
        if (((height && width) || isPosterAspect) && crop) {
            if (width && height) {
                imgBuilder = imgBuilder.width(w).height(h);
            } else {
                imgBuilder = imgBuilder.width(w).height(Math.floor(w / GLOBAL_POSTER_ASPECT));
            }
            imgBuilder = doCrop(imgBuilder);

        }
        imgSrc = imgBuilder.url();
    }

    let _altText: string;
    if (isFilmweb) {
        const i = (image as ICustomImage);
        _altText = i?.asset?.altText ?? i?.asset?.title ?? "";
    } else {
        const i = (image as FwPakkeImageDocument);
        _altText = i?.altText ?? i?.title ?? "";
    }

    return <NextImage src={imgSrc} alt={altText ?? _altText} priority={priority} className={className} sizes={sizes} width={w} height={h} loader={getImageLoader(isFilmweb, crop, GLOBAL_POSTER_ASPECT)} unoptimized={unoptimized} />
}

function doCrop(imageBuilder: ImageUrlBuilder): ImageUrlBuilder {
    return imageBuilder.fit("crop").crop("focalpoint").focalPoint(0.5, 0.5);
}
//#endregion

//#region [Props]
type CMSCroppedImageProps = {
    heightRatio?: number;
    image: FwPakkeImageDocument | ICustomImage;
    isFilmweb?: boolean;
    priority?: boolean;
    altText?: string;
    className?: string;
    sizes?: string;
    width: number;
};
//#endregion

//#region [Component]
export function CMSCroppedImage({ image, altText, isFilmweb = false, sizes, heightRatio, width, className }: CMSCroppedImageProps) {
    const imageLoader: ImageLoader = useCallback((p) => {
        const img = isFilmweb ? image as ICustomImage : (image!.image as Image);
        let builder = urlForAsset(p.src, isFilmweb).width(p.width).auto("format").fit("crop").crop("focalpoint");
        if (heightRatio) {
            builder = builder.height(Math.floor(p.width * heightRatio));
            if (img.hotspot) {
                builder = builder.focalPoint((img as Image).hotspot!.x, (img as Image).hotspot!.y);
            } else if ((img as ICustomImage).asset?.hotspot) {
                builder = builder.focalPoint((img as ICustomImage).asset.hotspot!.x, (img as ICustomImage).asset.hotspot!.y);
            }
        }
        return builder.url();
    }, [image, isFilmweb, heightRatio]);


    const assetRef = isFilmweb ? (image as ICustomImage)?.asset?._id : (image?.image as Image)?.asset?._ref;
    if (!assetRef) {
        return null;
    }
    const orgSize = getOrgSize(assetRef);

    let _altText: string;
    if (isFilmweb) {
        const i = (image as ICustomImage);
        _altText = i?.asset?.altText ?? i?.asset?.title ?? "";
    } else {
        const i = (image as FwPakkeImageDocument);
        _altText = i?.altText ?? i?.title ?? "";
    }

    return <NextImage src={assetRef} width={width} sizes={sizes} height={heightRatio ? width * heightRatio : width / orgSize.aspect} alt={altText ?? _altText} loader={imageLoader} className={className} />
}
//#endregion

//#region [Props]
type CMSPictureProps = {
    className?: string;
    children: ReactNode;
    isFilmweb?: boolean;
    image: FwPakkeImageDocument | ICustomImage;
    fallbackWidth: number;
    fallbackHeightRatio?: number;
    altText?: string;
};
//#endregion

//#region [Component]
export function CMSPicture({ className, isFilmweb = false, children, image, fallbackWidth, fallbackHeightRatio, altText }: CMSPictureProps) {

    const fallbackSrc = useMemo(() => {
        const img = isFilmweb ? image as ICustomImage : (image.image as Image);
        const assetRef = isFilmweb ? (img as ICustomImage).asset?._id : (img as Image).asset?._ref;
        if (!assetRef) {
            return undefined;
        }
        const size = getOrgSize(assetRef);
        const w = fallbackWidth;
        const h = Math.floor(fallbackHeightRatio ? (w * fallbackHeightRatio) : fallbackWidth / size.aspect);
        let builder = urlForAsset(assetRef, isFilmweb).width(w).height(h).fit("crop").auto("format").crop("focalpoint");

        if (img.hotspot) {
            builder = builder.focalPoint((img as Image).hotspot!.x, (img as Image).hotspot!.y);
        } else if ((img as ICustomImage).asset?.hotspot) {
            builder = builder.focalPoint((img as ICustomImage).asset.hotspot!.x, (img as ICustomImage).asset.hotspot!.y);
        }

        return builder.url();
    }, [fallbackHeightRatio, image, isFilmweb, fallbackWidth]);

    let _altText: string;
    if (isFilmweb) {
        const i = (image as ICustomImage);
        _altText = i?.asset?.altText ?? i?.asset?.title ?? "";
    } else {
        const i = (image as FwPakkeImageDocument);
        _altText = i?.altText ?? i?.title ?? "";
    }

    return (<picture className={className}>
        {React.Children.map(children, child => {
            // Checking isValidElement is the safe way and avoids a typescript
            // error too.
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { ...child.props, _isFilmweb: isFilmweb, _image: image });
            }
            return child;
        })}



        <img src={fallbackSrc} alt={altText ?? _altText} />

    </picture>)
}
//#endregion

//#region [Props]
type CMSPictureSourceProps = {
    media: string;
    _image?: FwPakkeImageDocument | ICustomImage; // Should not be used directly
    _isFilmweb?: boolean; // Should not be used directly
    sizes: number[];
    heightRatio?: number;
};
//#endregion

//#region [Component]
export function CMSPictureSource({ media, _image, _isFilmweb = false, sizes, heightRatio }: CMSPictureSourceProps) {
    const srcSet = useMemo(() => {
        const img = _isFilmweb ? (_image as ICustomImage) : (_image!.image as Image);
        const assetRef = _isFilmweb ? (img as ICustomImage).asset?._id : (img as Image).asset?._ref;
        if (!assetRef) {
            return undefined;
        }
        return sizes.map(s => {
            let builder = urlForAsset(assetRef!, _isFilmweb).width(s).auto("format").fit("crop");
            if (heightRatio) {
                builder = builder.height(Math.floor(s * heightRatio));

                if (img.hotspot) {
                    builder = builder.focalPoint((img as Image).hotspot!.x, (img as Image).hotspot!.y);
                } else if ((img as ICustomImage).asset?.hotspot) {
                    builder = builder.focalPoint((img as ICustomImage).asset.hotspot!.x, (img as ICustomImage).asset.hotspot!.y);
                }
            }
            return `${builder.url()} ${s}w`;
        }).join(",");
    },
        [sizes, _image, _isFilmweb, heightRatio]);

    return <source srcSet={srcSet} media={media} />
}
//#endregion

//#region [Styles]
const FullWidthCMSImage = styled(CMSImage)`
	width: 100%;
	height: auto;
	display: block;
`;

export { FullWidthCMSImage };
//#endregion



//#region [Other]
/**
 * Finds the index of the first wide image in the array.
 * @param images Array of images
 * @returns the index of the first wide image in the array. -1 if not found
 */
export function getFirstWideImageIndex(images: FwPakkeImageDocument[] | ICustomImage[]): number {
    if ((images?.length ?? 0) === 0) {
        return -1;
    }

    return images.findIndex((img: FwPakkeImageDocument | ICustomImage) => {
        const assetRef = img._type === "customImage" ? (img as ICustomImage).asset._id : ((img as FwPakkeImageDocument).image.asset as Reference)._ref;
        if (assetRef) {
            const size = getOrgSize(assetRef);
            return (size.width > size.height);
        }
        return false;
    });
}
//#endregion



//#region [Props]
type StaticImageProps = ImageProps & {
    image: StaticImageData | string;
    width: number | string; // override to make it non-optional
    height: number | string; // override to make it non-optional
};
//#endregion

//#region [Component]
export function StaticImage({ image, priority = false, altText, className, sizes, width, height }: StaticImageProps) {
    return <NextImage src={image} alt={altText ?? "Bilde"} priority={priority} className={className} sizes={sizes} width={toNumber(width)} height={toNumber(height)} />
}
//#endregion

//#region [Props]
type OgImageProps = {
    document?: IMovieArticle | ICultureArticle | INewsArticle | null;
    filmomtale?: Filmomtale | null;
};
//#endregion

//#region [Component]
export function OgImage({ document, filmomtale }: OgImageProps) {
    let image: Image | ICustomImage | undefined = document?.image as Image;
    let isFilmweb = false;
    if (!image) {
        isFilmweb = true;
        image = filmomtale?.mainImageV2 ?? filmomtale?.imagesV2?.[0];
    }
    if (!image) {
        return null;
    }

    const assetRef = isFilmweb ? (image as ICustomImage).asset?._id : (image as Image).asset?._ref;
    if (!assetRef) {
        return null;
    }

    const imgSrc = urlForAsset(assetRef, isFilmweb).auto("format").url();


    return <Head><meta property="og:image" content={imgSrc} key="ogImage" /></Head>
}
//#endregion