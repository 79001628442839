import Icons, { CUSTOM_ICONS } from "components/images/Icons";
import { getUrl, IMenuItem } from "lib/types/sanity";
import Link from "next/link";
import styled, { css } from "styled-components";
import { Hotlink } from 'lib/types/Settings';
import HotlinkMenuItem from "components/framework/Hotlink";
import { Dispatch, SetStateAction } from "react";





//#region [Component]
type SNavProps = {
	$isOpen: boolean;
}

const SNav = styled.nav<SNavProps>`
	position: absolute;
	z-index: 1000;
	right: 0;
	max-width: 100vw;
	transition: transform 0.2s;
	transform: translateX(100%);
	${props => props.$isOpen && `
		transform: translateX(0);
	`}

	ul {
		font-size: 1.5625rem;
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		gap: 5px;

		li {
			padding: .7em 2em .7em 3em;
			background-color: var(--color_menu_bg, #fff);

			a {
				position: relative;
				color: var(--color_menuitem, var(--color_menu_text, #000));

				svg {
					position: absolute;
					top: 50%;
					left: -2.5em;
					transform: translateY(-50%);

					width: 1.6em;
					height: auto;
				}
			}
		}
	}
`;

type SHotLinkListItemProps = {
	$alwaysShowInDropdown?: boolean;
}

const SHotLinkListItem = styled.li<SHotLinkListItemProps>`
	a {
		text-align: left;
	}
	${props => !props.$alwaysShowInDropdown && css`
		@media screen and (min-width: 950px) {
			display: none;
		}
	`}
`;
//#endregion

//#region [Props]
type DropDownMenuProps = {
	isOpen: boolean;
	menuItems?: IMenuItem[];
	hotlinks?: Hotlink[];
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	useAnchors?: boolean;
};



//#endregion

//#region [Component]
export default function DropDownMenu({ setIsOpen, isOpen = false, menuItems, hotlinks, useAnchors = false }: DropDownMenuProps) {
	if (!menuItems || menuItems.length === 0) {
		return null;
	}
	return <SNav $isOpen={isOpen} onClick={() => setIsOpen(false)}>
		<ul>
			{hotlinks?.map(hotlink => {
				return <SHotLinkListItem key={hotlink._key} $alwaysShowInDropdown={!!hotlink.alwaysShowInDropdown}><HotlinkMenuItem hotlink={hotlink} useAnchors={useAnchors} /></SHotLinkListItem>
			})}
			{menuItems.map((menuItem => {
				const url = getUrl(menuItem);
				if (!url) {
					return null;
				}
				return <li key={menuItem._key}><Link href={url}><MenuIcon menuItem={menuItem} /> {menuItem.title}</Link></li>
			}))}
		</ul>
	</SNav>
}
//#endregion

//#region [Props]
type MenuIconProps = {
	menuItem: IMenuItem;
};
//#endregion

//#region [Component]
function MenuIcon({ menuItem }: MenuIconProps) {
	if (menuItem.customIcon) {
		return CUSTOM_ICONS[menuItem.customIcon!];
	}
	if (menuItem.icon) {
		return Icons[menuItem.icon!];
	}
	return null;
}
//#endregion