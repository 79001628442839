import styled from "styled-components";

const lineThickness = 4;

//#region [Props]
type CloseButtonProps = {
	$isOpen: boolean;
}
//#endregion

//#region [Styles]


const SCloseButton = styled.button<CloseButtonProps>`
	color: var(--color_menu_text, #000);
	padding: 0;
	border: none;
	background: none;
	font-size: 0.8rem;
	@media screen and (min-width: 641px) {
		font-size: 1rem;
	}


	width: 100%;
	position: relative;
	min-width: 3.5em;
	padding-top: ${lineThickness * 7}px;
	@media screen and (min-width: 641px) {
		padding-top: ${lineThickness * 9}px;
	}


	@media screen and (min-width: 641px) {
		width: auto;
		text-indent: 0;
	}

	&:before, &:after {
		content: " ";
		display: block;
		border-radius: 10% / 50%;
		height: ${lineThickness}px;
		width: 100%;
		max-width: 2.5em;
		background: currentColor;
		transition: all 0.2s;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

	}
	&:before {
		top: 0;
		box-shadow: 0 ${lineThickness * 4}px 0 0 currentColor;
		@media screen and (min-width: 641px) {
			box-shadow: 0 ${lineThickness * 6}px 0 0 currentColor;
		}
	}

	&:after {
		top: ${lineThickness * 2}px;
		@media screen and (min-width: 641px) {
			top: ${lineThickness * 3}px;
		}
	}

	${props => props.$isOpen && `
		&:before {
			top: ${lineThickness * 2}px;
			@media screen and (min-width: 641px) {
				top: ${lineThickness * 3}px;
			}
			transform: translateX(-50%) rotate(45deg);
			box-shadow: 0 0 0 0 currentColor;
		}

		&:after {
			transform: translateX(-50%) rotate(-45deg);
		}
	`}

	&.open {
		&:before {
			top: ${lineThickness * 2}px;
			@media screen and (min-width: 641px) {
				top: ${lineThickness * 3}px;
			}
			transform: translateX(-50%) rotate(45deg);
			box-shadow: 0 0 0 0 currentColor;
		}

		&:after {
			transform: translateX(-50%) rotate(-45deg);
		}
	}

`;

export default SCloseButton;
//#endregion