
import { QueryClientProvider } from '@tanstack/react-query';
//import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'components/Layout';
import AdContextWrapper from 'components/framework/AdContextWrapper';
import Footer from 'components/framework/Footer';
import Menu from 'components/framework/Menu';
import GlobalStyle from 'globalStyles';
import { queryClient } from "lib/client/movieinfo";
import { useBaseTitle } from 'lib/client/useTitle';
import PublicRuntimeConfig from 'lib/types/PublicRuntimeConfig';
import { ICommonAppData, Settings } from 'lib/types/sanity';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: PublicRuntimeConfig };

const DynamicAdnuntiusAd = dynamic(() =>
	import('components/framework/AdnuntiusAd').then((mod) => mod.default),
	{ ssr: false }
);

//#region [Props]
type FWPakkeProps = AppProps & {
	pageProps: {
		appData: ICommonAppData
	};
};
//#endregion


//#region [Component]
function FWPakke({ Component, pageProps }: FWPakkeProps) {
	const router = useRouter();
	const settings = pageProps?.appData?.settings as Settings;
	const title = useBaseTitle(settings);

	const isSkjermProgram = router.pathname === "/skjerm";
	console.debug("Router pathname", router.pathname);
	const hideTopAdOnMobile = !!(router.pathname === "/film/[edi]" || router.pathname === "/program" || router.pathname === "/velgfilm" || router.pathname === "/kommer");
	const showAdAfterScroll = router.pathname === "/";
	console.debug("Show ad after Scroll", showAdAfterScroll);
	console.debug("Router pathname", router.pathname, hideTopAdOnMobile.toString());
	return (<>
		<ErrorBoundary boundaryName='ROOT'>
			<ErrorBoundary boundaryName='GLOBAL STYLES'>
				<GlobalStyle appData={pageProps?.appData} />
			</ErrorBoundary>
			<ErrorBoundary boundaryName='HEAD'>
				<Head>
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
					<title>{title}</title>
					<link rel="icon" type="image/png" sizes="192x192" href={`/${publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME}/KinoLogo192x192.png`} />
					<link rel="icon" type="image/png" sizes="512x512" href={`/${publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME}/KinoLogo512x512.png`} />
					<link rel="apple-touch-icon" href={`/${publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME}/KinoLogo192x192.png`} />
					<link rel="apple-touch-icon" sizes="512x512" href={`/${publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME}/KinoLogo512x512.png`} />

					<link rel="manifest" href="/api/manifest.webmanifest" />

					<meta name="application-name" content={publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME_READABLE} />
					<meta name="apple-mobile-web-app-capable" content="yes" />
					<meta name="apple-mobile-web-app-status-bar-style" content="default" />
					<meta name="apple-mobile-web-app-title" content={publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME_READABLE} />
					<meta name="description" content={`Nettsider og program for ${publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME_READABLE}`} />
					<meta name="format-detection" content="telephone=no" />
					<meta name="mobile-web-app-capable" content="yes" />
					<meta name="theme-color" content={settings?.color_primary?.hex} />

					<meta property="og:type" content="website" />
					<meta property="og:title" content={publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME_READABLE} key="title" />

					<meta property="og:description" content={`Nettsider og program for ${publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME_READABLE}`} />
					<meta property="og:site_name" content={publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME_READABLE} />

					<meta property="og:url" content={publicRuntimeConfig?.NEXT_PUBLIC_BASE_URL} key="ogUrl" />
					<meta property="og:image" content={`${publicRuntimeConfig?.NEXT_PUBLIC_BASE_URL}${publicRuntimeConfig?.NEXT_PUBLIC_CINEMA_NAME}/KinoLogo192x192.png`} key="ogImage" />

					{/*

					<link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
					<link rel="shortcut icon" href="/favicon.ico" />

					*/}

					{/**
					<meta name="msapplication-config" content="/icons/browserconfig.xml" />
					<meta name="msapplication-TileColor" content="#2B5797" />
					<meta name="msapplication-tap-highlight" content="no" />
					*/}
				</Head>
				{!isSkjermProgram && <>
					<Script id="adnuntiusMainScript">
						{`
						(function(d, s, e, t){e=d.createElement(s);e.type='text/java'+s;e.async='async';e.src='http'+('https:'===location.protocol?'s':'')+'://cdn.adnuntius.com/adn.js';t=d.getElementsByTagName(s)[0]; t.parentNode.insertBefore(e, t);})(document,'script');
						window.adn = window.adn || {}; adn.calls = adn.calls || [];
					`}
					</Script>
					{publicRuntimeConfig?.NEXT_PUBLIC_GTM_ID && <Script id="google-tag-manager" strategy='afterInteractive'>
						{`
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','${publicRuntimeConfig.NEXT_PUBLIC_GTM_ID}');
						`}
					</Script>
					}
				</>}
			</ErrorBoundary>
			{/* Global Site Code Pixel - Facebook Pixel */}
			{/*<FacebookPixel pixelId={[process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_1 as string, process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_2 as string]} />*/}
			{/*<GA4 measurementId={process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID as string} />*/}
			<ErrorBoundary boundaryName='LAYOUT'>
				<Layout>
					<AdContextWrapper appData={pageProps?.appData} isSkjermProgram={isSkjermProgram}>
						{!isSkjermProgram && <DynamicAdnuntiusAd appData={pageProps?.appData} index={0} hideOnMobile={hideTopAdOnMobile} debugLabel='_app' showAdAfterFirstScroll={showAdAfterScroll}/>}
						{!isSkjermProgram && <ErrorBoundary boundaryName='MENU'>
							<Menu appData={pageProps?.appData} />
						</ErrorBoundary>}
						<ErrorBoundary boundaryName='COMPONENT'>
							<Component {...pageProps} />
						</ErrorBoundary>
					</AdContextWrapper>
				</Layout>
			</ErrorBoundary>
			{!isSkjermProgram &&
				<ErrorBoundary boundaryName='FOOTER'>
					<Footer {...pageProps} />
				</ErrorBoundary>
			}
		</ErrorBoundary>
	</>);
}

function FWPakkeWithQueryClient(props: FWPakkeProps) {
	return (
		<QueryClientProvider client={queryClient}>
			<FWPakke {...props} />
			{/*<ReactQueryDevtools /> CRASHES ATM*/}
		</QueryClientProvider>
	);
}



export default FWPakkeWithQueryClient;
//#endregion
