import { getBoldWeight } from "lib/client/fonts";
import { ICommonAppData } from "lib/types/sanity";
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

//#region [Styles]
const SSignupForm = styled.div`
	input {
		line-height: 1.5;
		font-family: "Open Sans",Helvetica,Arial,sans-serif;
		font-size: 100%;
	}

	input[type='email'] {
		display: block;
		min-width: 80%;
		padding: 0.25rem 0.5rem;
		color: #000000;

	}

	input[type='submit'] {
		color: inherit;
		background: none;
    	border: 2px solid currentColor;
    	text-transform: uppercase;
    	padding: 0.25rem 0.5rem;
    	margin-top: 0.5rem;
	}

    label {
        font-size: 1.5em;
        display: block;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;

        font-family: "Open Sans",Helvetica,Arial,sans-serif; font-weight: bold;

    }
`;

const SLabel = styled.label`
	font-size: 0.9rem;
`;

const STermsError = styled.div`
	color: red;
`;
//#endregion

//#region [Props]
type MailChimpSignupFormProps = {
	value: {
		signupForm: string;
		gdprFormId: number;
	};
};
//#endregion


//#region [Component]
export default function ShowtimeAnalyticsSignupForm({ value }: MailChimpSignupFormProps) {
	const [hasAcceptedTc, setHasAcceptedTc] = useState(false);
	const [showTcError, setShowTcError] = useState(false);

	const onSubmit = useCallback((event: SubmitEvent) => {
		event.preventDefault();
		if (!hasAcceptedTc) {
			setShowTcError(true);
		} else {
			const form = document.querySelector("#ngxForm") as HTMLFormElement;
			form.submit();
			setShowTcError(false);
			console.log("Form submitted");
		}

	}, [hasAcceptedTc]);

	useEffect(() => {
		//const button = document.querySelector("#mc-newsletter-signup input[type='button']") as HTMLInputElement;
		const form = document.querySelector("#ngxForm") as HTMLFormElement;
		if (form) {
			form.addEventListener("submit", onSubmit);
			return () => { form.removeEventListener("submit", onSubmit) };
		}
	}, [onSubmit]);



	return (<div>
		<SSignupForm dangerouslySetInnerHTML={{ __html: value.signupForm }}></SSignupForm>
		<div>
			<SLabel htmlFor="personvernCheckbox0">
				<input id="personvernCheckbox0" name="personvernCheckbox0" type="checkbox" checked={hasAcceptedTc} onChange={() => setHasAcceptedTc(!hasAcceptedTc)} /> Godta <a href={`https://skynet.filmweb.no/filmwebforms/form/displayform?id=${value.gdprFormId}`} target="_blank" rel="noreferrer">personvernerklæringen</a>
			</SLabel>
			{showTcError && <STermsError>Du må godta personvernerklæringen</STermsError>}
		</div>
	</div>);
}
//#endregion