import { ReactElement } from "react";
import styled from "styled-components";


//#region [Styles]
const Main = styled.main`
	padding-top: 0.1px; // minimal padding to prevent margin collapse
	padding-bottom: 0.1px;
	max-width: 100%;
	display: flex;
	flex-direction: column;
`;
//#endregion

//#region [Props]
type LayoutProps = {
	children: React.ReactNode;
	className?: string;
};
//#endregion


//#region [Component]
const Layout = ({ children, className }: LayoutProps) => {
	return <Main className={className}>{children}</Main>;
};

export default Layout;
//#endregion