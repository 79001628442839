import { PortableText, PortableTextComponents, PortableTextMarkComponentProps, PortableTextTypeComponent } from '@portabletext/react';
import { ArbitraryTypedObject, PortableTextBlock } from '@portabletext/types';
import { Slug } from '@sanity/types';
import CMSImage, { FullWidthCMSImage } from 'components/images/Image';
import MailChimpSignupForm from 'components/richtext/MailchimpSignupForm';
import getYouTubeID from 'get-youtube-id';
import FwPakkeImageDocument from 'lib/types/FwPakkeImageDocument';
import { getUrlForDocType } from 'lib/types/sanity';
import Link from 'next/link';
import YT from 'react-youtube';
import styled from 'styled-components';
import Table from 'components/richtext/Table';
import MailChimpSignupFormWithUrl from 'components/richtext/MailchimpSignupFormWithUrl';
import ShowtimeAnalyticsSignupForm from "components/richtext/ShowtimeAnalyticsSignupForm";
import { Facebook, Instagram, TikTok, Twitter } from "@filmweb/nextjs";
export { QUERY_FRAGMENT_BODYTEXT, QUERY_FRAGMENT_INGRESS, createRichTextQueryFragment } from "lib/utils/richText";

//#region [Styles]
const YTStyled = styled(YT)`
	width: 100%;
	height: 0;
	padding-bottom: ${`${100 * 360 / 740}%`};
	position: relative;

	iframe {
		position: absolute;
		top: 0;
		width: 100%;
		left: 0;
		height: 100%;
	}
`;
//#endregion

//#region [Props]
type YouTubeProps = {
    value: {
        _key: string;
        _type: string;
        url: string
    }
}
//#endregion

//#region [Component]
const YouTube = ({ value }: YouTubeProps) => {
    const { url } = value;
    if (!url) {
        return null;
    }

    const ytId = getYouTubeID(url);
    if (!ytId) {
        return null;
    }

    return <YTStyled videoId={ytId} />
};
//#endregion


//#region [Props]
type InlineImageProps = {
    value: {
        placement: "alignLeft" | "fullWidth" | "alignRight";
        title: string;
        imageRef: FwPakkeImageDocument;
    }
};
//#endregion


//#region [Styles]
const SLeftAlignedImage = styled.div`
	@media screen and (min-width: 641px) {
		padding-right: 50px;
		float: left;
		width: 66%;
		padding-bottom: 30px;
		margin-left: -200px;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	& + * {
		margin-right: -200px;
		&::after {
			content: "";
			display: block;
			height: 0;
			clear: both;
		}
	}
`;

const SRightAlignedImage = styled.div`
	@media screen and (min-width: 641px) {
		float: right;
		width: 66%;
		padding-left: 50px;
		padding-bottom: 30px;
		margin-right: -200px;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	& + * {
		margin-left: -200px;
		&::after {
			content: "";
			display: block;
			height: 0;
			clear: both;
		}
	}
`;


const SFullWidthImage = styled(FullWidthCMSImage)`
	margin-bottom: 30px;
	width: 100%;


	@media screen and (min-width: 641px) {
		width: calc(100% + 200px);
		position: relative;
		left: -100px;
		right: -100px;
	}

`;
//#endregion

//#region [Component]
const InlineImage = ({ value }: InlineImageProps) => {
    const { placement, imageRef, title } = value;

    switch (placement) {
        case "alignLeft":
            return <SLeftAlignedImage><CMSImage image={imageRef} altText={title} sizes="(min-width: 641px) 600px, 100vw" width={600} /></SLeftAlignedImage>;
            break;
        case "alignRight":
            return <SRightAlignedImage><CMSImage image={imageRef} altText={title} sizes="(min-width: 641px) 600px, 100vw" width={600} /></SRightAlignedImage>;
            break;
        default:
            return <SFullWidthImage image={imageRef} altText={title} sizes="(min-width: 641px) 1100px, 100vw" width={600} />;
    }

};
//#endregion


//#region [Other]
const TYPES_FULL: Record<string, PortableTextTypeComponent<any>> = {
    youtube: YouTube,
    inlineImage: InlineImage,
    mailchimpForm: MailChimpSignupForm,
    mailchimpFormWithUrl: MailChimpSignupFormWithUrl,
    showtimeAnalytics: ShowtimeAnalyticsSignupForm,
    table: Table,
    instagramEmbed: Instagram,
    tiktokEmbed: TikTok,
    twitterEmbed: Twitter,
    facebookEmbed: Facebook
};

function NoRender() { return null; };

const TYPES_NO_CUSTOMCOMPONENTS: Record<string, PortableTextTypeComponent<any>> = {
    youtube: NoRender,
    inlineImage: NoRender,
    mailchimpForm: NoRender,
    mailchimpFormWithUrl: NoRender,
    showtimeAnalytics: NoRender,
    table: NoRender,
    instagramEmbed: NoRender,
    tiktokEmbed: NoRender,
    twitterEmbed: NoRender,
    facebookEmbed: NoRender
};


const MARKS_NO_LINKS = {
    internalLink: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        return <span>{children}</span>;
    },
    mailTo: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        return <span>{children}</span>;
    },
    link: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        return <span>{children}</span>;
    },
};

const MARKS_LINKS = {
    internalLink: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        let url: string | null = null;
        try {
            url = getUrlForDocType(value.docType as string, value.slug as Slug);
        }
        catch (e) {
            console.error("Value", value);
        }
        if (!value.slug || !url) {
            return <span>{children}</span>
        }
        return (
            <Link href={url}>
                {children}
            </Link>
        )
    },
    mailTo: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        return (<Link href={`mailto:${value.mailto}`}>
            {children}
        </Link>);
    }
};

function buildComponents(disableLinks: boolean = false, disableCustomComponents: boolean = false): PortableTextComponents {
    return {
        types: disableCustomComponents ? TYPES_NO_CUSTOMCOMPONENTS : TYPES_FULL,
        marks: disableLinks ? MARKS_NO_LINKS : MARKS_LINKS
    };
}
//#endregion

//#region [Props]
type RichTextProps = {
    value?: (PortableTextBlock | ArbitraryTypedObject)[],
    className?: string,
    disableLinks?: boolean; // for use when the rich text is itself with a link
    disableCustomComponents?: boolean; // for use when images should not be allowed
}
//#endregion

//#region [Styles]
const StyledRichText = styled.div`
	line-height: 1.6;
	p {
		padding: 0.1px 0; // to prevent margin collapse
	}

	a {
		color: var(--color_primary);
		text-decoration: underline;
	}
`;
//#endregion

//#region [Component]
const RichText = ({ value, className, disableLinks = false, disableCustomComponents = false }: RichTextProps) => {
    if (!value || value.length === 0) {
        return null;
    }
    const components = buildComponents(disableLinks, disableCustomComponents);
    return <StyledRichText className={className}><PortableText value={value} components={components} /></StyledRichText>
}

export default RichText;
//#endregion
