export const BASE_SIZE = 16;
export const BODY_FONT = "Open+Sans:ital,wght@0,400;0,700;0,800;1,400;1,700";

export const VALID_HEADER_FONTS = {
	"Merriweather": ["Merriweather:wght@400;700", "700"],
	"Montserrat": ["Montserrat:wght@400;700", "700"],
	"Arvo": ["Arvo:wght@400;700", "700"],
	"Playfair Display": ["Playfair+Display:wght@400;900", "900"],
	"Roboto Condensed": ["Roboto+Condensed:wght@400;700", "700"],
	"Gravitas One": ["Gravitas+One", "normal"],
	"Rubik": ["Rubik:wght@400;700", "700"]
};

export function getBoldWeight(name: string) {
	return VALID_HEADER_FONTS[(name as keyof typeof VALID_HEADER_FONTS)][1]
}

export function toRem(pxSize: number, baseSize: number = BASE_SIZE): string {
	return `${pxSize / baseSize}rem`;
}

export function getHeaderFont(name: keyof typeof VALID_HEADER_FONTS | undefined | null): string {
	if (name) {
		return `&family=${VALID_HEADER_FONTS[name][0]}`;
	}
	return "";
}
