import styled, { css } from "styled-components";
import VelgFilmIconMeny from "@/public/VelgFilmMeny.svg";
import KulturIconMeny from "@/public/KulturIconMeny.svg";
import ProgramIconMeny from "@/public/KinoIconMeny.svg";
import { Hotlink } from 'lib/types/Settings';
import Link from "next/link";


//#region [Styles]
const SHotlink = styled.a<LinkProps>`
	color: var(--color_menu_text, #000);
	text-decoration: none;
	text-align: center;
	&:hover {
		text-decoration: underline;
	}
	${props => props.$hideOnSmallScreens && css`
		display: none;
	`}
	@media screen and (min-width: 950px) {
		display: block;
	}
`;

const SVelgFilmIcon = styled(VelgFilmIconMeny)`
	display: block;
	margin: 0 auto;
	height: 38px;

`;

const SKulturIcon = styled(KulturIconMeny)`
	display: block;
	margin: 0 auto;
	height: 38px;
`;

const SProgramIcon = styled(ProgramIconMeny)`
	display: block;
	margin: 0 auto;
	height: 38px;
`;
//#endregion


//#region [Props]
type LinkProps = {
	$hideOnSmallScreens?: boolean;
}

type HotlinkProps = {
	hotlink: Hotlink;
	useAnchors?: boolean;
} & LinkProps;
//#endregion

//#region [Component]
export default function HotlinkMenuItem({ hotlink, $hideOnSmallScreens: hideOnSmallScreens = false, useAnchors = false }: HotlinkProps) {
	let href = "";
	let icon = null;
	const prefix = useAnchors ? "#" : "/";
	switch (hotlink._type) {
		case "velgfilm":
			href = "/velgfilm";
			icon = <SVelgFilmIcon />;
			break;
		case "program":
			href = prefix + "program";
			icon = <SProgramIcon />;
			break;
		case "kulturprogram":
			href = prefix + "kulturprogram";
			icon = <SKulturIcon />;
			break;
	}

	if (!href) {
		return null;
	}
	return <Link href={href} passHref legacyBehavior>
		<SHotlink $hideOnSmallScreens={hideOnSmallScreens}>
			{icon}
			{hotlink.label}
		</SHotlink>
	</Link>;
}
//#endregion