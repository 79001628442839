import imageUrlBuilder from "@sanity/image-url";
import { ImageUrlBuilder } from "@sanity/image-url/lib/types/builder";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { Image } from "@sanity/types";
import PublicRuntimeConfig from "lib/types/PublicRuntimeConfig";
import { FwPakkeImageDocument } from "lib/types/sanity";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: PublicRuntimeConfig };

const imgUrlBuilder = imageUrlBuilder({ projectId: publicRuntimeConfig?.NEXT_PUBLIC_SANITY_PROJECTID, dataset: publicRuntimeConfig?.NEXT_PUBLIC_SANITY_DATASET });

export function urlFor(source: (Image | FwPakkeImageDocument)): ImageUrlBuilder {
	const img = source._type === "image" ? source : source.image;
	return imgUrlBuilder.image(img as SanityImageSource);
}

export function urlForAsset(source: string, isFilmweb: boolean = false): ImageUrlBuilder {
	let builder = imgUrlBuilder;
	if (isFilmweb) {
		builder = builder.projectId(publicRuntimeConfig?.NEXT_PUBLIC_SANITY_FILMWEB_PROJECTID).dataset(publicRuntimeConfig?.NEXT_PUBLIC_SANITY_FILMWEB_DATASET);
	}
	return builder.image(source);
}
