import { getBoldWeight, BASE_SIZE } from 'lib/client/fonts';
import Color from 'lib/types/Color';
import { ICommonAppData } from 'lib/types/sanity';
import { createGlobalStyle } from 'styled-components';

//#region [Props]
type GlobalStyleProps = {
	appData: ICommonAppData
};
//#endregion

/**
 font-family: 'Arvo', serif;
font-family: 'Gravitas One', cursive;
font-family: 'Merriweather', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Roboto Condensed', sans-serif;
font-family: 'Rubik', sans-serif;
 */

//#region [Styles]
const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
	:root {
		--top-menu-height: 80px;
		--mobile-menu-height: 60px;

		${props => {
				const colorVars = [];
				if (props?.appData?.settings) {
					const propNames = Object.getOwnPropertyNames(props.appData.settings);
					// output colors
					for (const propName of propNames) {

						if (propName.startsWith("color_")) {
							const col = props.appData.settings[propName] as Color;
							if( propName === "color_ticketbutton_bg" && !propNames.includes("color_ticketbutton_datebg_other")) {
								if( col.hex === "#000000") {
									// if the ticketbutton is set to black, and no "every other other date" color is set, we must set some sensible defaults
									colorVars.push(`--color_ticketbutton_datebg_other: #fff; `);
									colorVars.push(`--color_ticketbutton_datetext_other: #000; `);
								} else if( col.hex === "#ffffff") {
									colorVars.push(`--color_ticketbutton_datebg_other: #000; `);
									colorVars.push(`--color_ticketbutton_datetext_other: #fff; `);
								}
							}
							if( col?.rgb ) {
								colorVars.push(`--${propName}: rgb(${col.rgb.r}, ${col.rgb.g}, ${col.rgb.b});`);
								// allow every color to have an 80% opacity value
								colorVars.push(`--${propName}_rgb: ${col.rgb.r}, ${col.rgb.g}, ${col.rgb.b};`);
							}

						}
					}

					if( !propNames.includes("color_ticketbutton_bg") && !propNames.includes("color_ticketbutton_datebg_other")) {
						// default bg color will be black, and no "every other other date" color is set, we must set some sensible defaults
						colorVars.push(`--color_ticketbutton_datebg_other: #fff; `);
						colorVars.push(`--color_ticketbutton_datetext_other: #000; `);
					}

				}
				return colorVars.join("");
			}
		}

		${props => {
			if( props?.appData?.settings?.font_headers) {
				return `--font_bold: ${getBoldWeight(props.appData.settings.font_headers)};`;
			}
			return `--font_bold: bold;`;
		}}

		${props => {
			if( props?.appData?.settings?.font_headers) {
				return `--font_family: "${props.appData.settings.font_headers}",Helvetica,Arial,sans-serif;`
			}
			return `--font_family: "Open Sans",Helvetica,Arial,sans-serif;`;
		}}
	}

	html {
		max-width: 100vw;
		overflow-x: hidden;
	}

	body {
		font-family: "Open Sans",Helvetica,Arial,sans-serif;
		margin: 0 0 var(--mobile-menu-height) 0;
		padding: 0;
		background-color: var(--color_body_bg, #fff);
		color: var(--color_text);
		text-rendering: optimizeLegibility;
		max-width: 100vw;
		overflow-x: hidden;


		@media screen and (min-width: 641px) {
			margin: 0;
		}

		* {
			box-sizing: border-box;
		}
	}

	button, option, select {
		font-family: "Open Sans",Helvetica,Arial,sans-serif;
	}

	h1, h2, h3, h4, h5, h6 {
		${props => {
			if( props?.appData?.settings?.font_headers) {
				return `font-family: "${props.appData.settings.font_headers}",Helvetica,Arial,sans-serif; font-weight: ${getBoldWeight(props.appData.settings.font_headers)}`
			}
			return `font-family: "Open Sans",Helvetica,Arial,sans-serif; font-weight: bold;`;
		}}
	}

	a {
		color: var(--color_text);
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	.adnDebugDataDiv {
		font-size: 12px;
	}
`;
//#endregion

export default GlobalStyle;