const imageSizeRegExp = /^image-.*?-(\d+)x(\d+)-.*$/i;

type Size = {
    width: number,
    height: number,
    aspect: number
};
export function getOrgSize(assetRef: string): Size {

    const res = imageSizeRegExp.exec(assetRef);
    const w = parseInt(res![1]);
    const h = parseInt(res![2]);
    return { width: w, height: h, aspect: w / h };
}