const MARKDEFS = `
    markDefs[]{
        ...,
        _type == "internalLink" => {
            "slug": select(
                @.reference->slug != null => @.reference->slug,
                @.reference->kulNr != null => @.reference->kulNr,
                @.reference->edi != null => @.reference->edi,
                @.reference->file != null => @.reference->file.asset->,
                null
            ),
            "docType": @.reference->_type
        }
    }
`;

export const QUERY_FRAGMENT_BODYTEXT = `bodyText[] {
    ...,
    _type == "inlineImage" => {
		"imageRef": @.imageRef->
    },
	${MARKDEFS}
}`;

export const QUERY_FRAGMENT_INGRESS = `ingress[] {
    ...,
    _type == "inlineImage" => {
		"imageRef": @.imageRef->
    },
	${MARKDEFS}
}`;

export function createRichTextQueryFragment(fieldName: string): string {
	return `${fieldName}[] {
		...,
		_type == "inlineImage" => {
			"imageRef": @.imageRef->
		},
		${MARKDEFS}
	}`;
}